// TODO : PRICE TICKER ADDRESS SETTINGS

// EDXA OLD TICKER
/* const priceContracts: { cakeAddress: string; busdAddress: string; lpAddress: string } = {
  cakeAddress: '0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
  busdAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  lpAddress: '0xa01584d303a339fc62586e1091a44E227B4BF3d6',
} */

const priceContracts: { cakeAddress: string; busdAddress: string; lpAddress: string } = {
  cakeAddress: '0x952cEbF65f3A9F11ef0222Aefe0608d26621B097',
  busdAddress: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  lpAddress: '0x9c56cd3f60dc3981289241ac880aa253dcbfd574',
}
export default priceContracts
